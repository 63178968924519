import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardIcon from "../../Card/CardIcon.js";
import CardFooter from "../../Card/CardFooter.js";

import styles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const Tile = props => {
  const classes = useStyles();
  const { type, title, icon, data, explanation } = props;
  return (
    <Card>
      <CardHeader color={type} stats icon>
        <CardIcon color={type}>{icon}</CardIcon>
        <p className={classes.cardCategory}>{title}</p>
        <h4 className={classes.cardTitle}>{data}</h4>
      </CardHeader>
      <CardFooter stats>{explanation}</CardFooter>
    </Card>
  );
};

export default Tile;
