import React, { Component } from "react";
import Modal from "../../Modal";

import * as api from "../../../Services/apiClient";

class ModalImportacaoAtividades extends Component {
  INITIAL_STATE = {
    showInstructions: false,
    file: null,
    isUploading: false,
    error: null
  };
  state = this.INITIAL_STATE;

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.fileInput.current.value = null;
      this.setState(this.INITIAL_STATE);
    }
  }

  toggleInstructions = () => {
    this.setState({
      showInstructions: !this.state.showInstructions
    });
  };

  setFile = e => {
    this.setState({
      file: e.target.files[0]
    });
  };

  upload = file => {
    this.setState({
      isUploading: true,
      error: null
    });
    api
      .uploadAtividades(file)
      .then(response => {
        this.setState({
          isUploading: false
        });
        this.props.onUploaded();
        this.onCancel();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isUploading: false,
          error: error.message || "Erro ao importar atividades"
        });
      });
  };

  render() {
    const { isOpen, onCancel } = this.props;
    const { showInstructions, file, isUploading, error } = this.state;

    return (
      <Modal title="IMPORTAR ATIVIDADES" isOpen={isOpen} onClose={onCancel}>
        <p className="text-justify">
          Importe suas atividades através de uma planilha Excel com extensão
          .xlsx.
        </p>
        <div className="d-block pb-3">
          <small
            className="text-info btn-toggler"
            onClick={this.toggleInstructions}
          >
            {showInstructions ? "Esconder instruções" : "Mostrar instruções"}
          </small>
          <div
            className={`painel-instrucoes ${
              showInstructions ? "d-block" : "d-none"
            }`}
          >
            <p className="text-justify">
              O arquivo deve possuir apenas 1 aba, com uma tabela contendo
              cabeçalho e 4 colunas:
            </p>

            <ul>
              <li>Data: no formato dd/mm/aaaa;</li>
              <li>Inicio: no formato hh:mm:ss;</li>
              <li>Conclusao: no formato hh:mm:ss;</li>
              <li>Descricao: texto descritivo da sua atividade;</li>
            </ul>
            <table className="tabela-exemplo-importacao">
              <thead>
                <tr>
                  <td>Data</td>
                  <td>Inicio</td>
                  <td>Conclusao</td>
                  <td>Descricao</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>24/03/2020</td>
                  <td>14:05:08</td>
                  <td>15:10:07</td>
                  <td>Minha Atividade</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <label>Escolha um arquivo:</label>
          <input
            type="file"
            accept=".xlsx"
            onChange={this.setFile}
            disabled={isUploading}
            ref={this.fileInput}
          />
        </div>
        {error && <div className="alert alert-danger mt-3">{error}</div>}
        <div className="float-right mt-3">
          <button
            className="btn btn-primary mr-2"
            onClick={onCancel}
            disabled={isUploading}
          >
            Cancelar
          </button>
          <button
            disabled={!file || isUploading}
            className="btn btn-danger"
            onClick={() => {
              this.upload(file);
            }}
          >
            Importar
          </button>
        </div>
      </Modal>
    );
  }
}

export default ModalImportacaoAtividades;
