import React, { Component } from "react";
import Modal from "../Modal";
import PropTypes from "prop-types";

class ModalErro extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    message: PropTypes.string
  };

  render() {
    const { isOpen, message, onCancel } = this.props;

    return (
      <Modal title="ERRO" isOpen={isOpen} onClose={onCancel}>
        <p className="py-3">{message}</p>
        <button className="btn btn-danger float-right" onClick={onCancel}>
          Ok
        </button>
      </Modal>
    );
  }
}

export default ModalErro;
