import React from "react";
import { FaCheck } from "react-icons/fa";

const NewTaskNotification = () => {
  return (
    <div className="task__notification">
      <FaCheck />
      Atividade adicionada com sucesso!
    </div>
  );
};

export default NewTaskNotification;
