import React from "react";

import * as constants from "../../constants";
import LocalStorage from "../../Utils/LocalStorage";

export default class ActOnFilterChange extends React.Component {
  state = {
    isLoading: false
  };

  componentDidUpdate(prevProps) {
    const filtro = this.props.filtro;
    const prevFiltro = prevProps.filtro;
    const usuario = this.props.usuarioAtividade;
    const prevUsuario = prevProps.usuarioAtividade;
    if (filtro !== prevFiltro || usuario !== prevUsuario) this.act();
  }

  componentDidMount() {
    this.act();
  }

  act = () => {
    const codigoUsuario = this.props.usuarioAtividade;
    if (
      !codigoUsuario ||
      codigoUsuario === 0 ||
      (this.props.options.tipo_usuario === constants.TipoUsuario.Colaborador &&
        codigoUsuario !== LocalStorage.usuario())
    )
      return;

    this.setState({
      isLoading: true
    });

    this.doActOnFilterChange(codigoUsuario, this.props.filtro);
  };

  doActOnFilterChange = (codigoUsuario, filtro) => {
    throw new Error("this is an abstract class");
  };
}
