import React from "react";
import { FaTasks, FaClock, FaStopwatch, FaBusinessTime } from "react-icons/fa";

import * as DateHelper from "../../Utils/DateHelper";
import Tile from "./Tile";
import LineChart from "./LineChart";

const ts = require("timespan");

const Dashboard = props => {
  const { dados } = props;

  if (!dados) return null;
  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <Tile
            type="success"
            icon={<FaTasks />}
            explanation="Quantidade total de atividades"
            title="Total"
            data={dados.quantidade_total}
          />
        </div>
        <div className="col-lg-4 col-md-6">
          <Tile
            type="info"
            icon={<FaClock />}
            explanation="Tempo total de atividades"
            title="Total"
            data={DateHelper.formatTimeSpan(ts.fromSeconds(dados.tempo_total))}
          />
        </div>
        <div className="col-lg-4 col-md-6">
          <Tile
            type="warning"
            icon={<FaStopwatch />}
            explanation="Tempo total de intervalo"
            title="Total"
            data={DateHelper.formatTimeSpan(
              ts.fromSeconds(dados.intervalo_total)
            )}
          />
        </div>
        <div className="col-lg-4 col-md-6">
          <Tile
            type="success"
            icon={<FaTasks />}
            explanation="Média de quantidade de atividades"
            title="Média"
            data={dados.quantidade_media.toFixed(2)}
          />
        </div>
        <div className="col-lg-4 col-md-6">
          <Tile
            type="info"
            icon={<FaBusinessTime />}
            explanation="Média de duração das atividades"
            title="Média"
            data={DateHelper.formatTimeSpan(ts.fromSeconds(dados.tempo_media))}
          />
        </div>
        <div className="col-lg-4 col-md-6">
          <Tile
            type="warning"
            icon={<FaBusinessTime />}
            explanation="Média de duração de intervalo"
            title="Média"
            data={DateHelper.formatTimeSpan(
              ts.fromSeconds(dados.intervalo_media)
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <LineChart
            type="success"
            series={[dados.atividades_por_dia.map(a => a.valor)]}
            labels={dados.atividades_por_dia.map(a => a.dia)}
            title={"Quantidade de atividades por dia"}
          />
        </div>
        <div className="col-12">
          <LineChart
            type="info"
            series={[dados.tempo_por_dia.map(a => a.valor / 3600)]}
            labels={dados.tempo_por_dia.map(a => a.dia)}
            title={"Tempo trabalhado por dia (em horas)"}
          />
        </div>
        <div className="col-12">
          <LineChart
            type="warning"
            series={[dados.intervalo_por_dia.map(a => a.valor / 60)]}
            labels={dados.intervalo_por_dia.map(a => a.dia)}
            title={"Tempo de intervalo por dia (em minutos)"}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
