import React, { Component } from "react";
import { MdMoreVert } from "react-icons/md";

import * as DateHelper from "../../Utils/DateHelper";

import DropDownAtividade from "./DropDownAtividade";
import NewTaskNotification from "./NewTaskNotification";

export default class LineAtividade extends Component {
  render() {
    const {
      atividade,
      now,
      onEdit,
      onDelete,
      onFinish,
      canShowOptions,
      ...rest
    } = this.props;
    return (
      <div
        key={atividade.codigo}
        {...rest}
        className={`card task ${atividade.conclusao === null &&
          " is-running"} ${atividade.isNew && " is-success"}`}
        id={atividade.codigo}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 col-12">
              <span>
                {DateHelper.getDateFormatted(atividade.inicio, "DD/MM")}&nbsp;
                {DateHelper.getDateFormatted(atividade.inicio, "HH:mm")} -{" "}
                {atividade.conclusao ? (
                  DateHelper.getDateFormatted(atividade.conclusao, "HH:mm")
                ) : (
                  <i>agora</i>
                )}
                &nbsp;|&nbsp;
                <strong>
                  {DateHelper.getDurationFormatted(
                    DateHelper.dateDiff(
                      atividade.inicio,
                      atividade.conclusao || now
                    )
                  )}
                </strong>
              </span>
            </div>
            <div className="col-lg-7 col-11 text-truncate">
              <span>{atividade.descricao}</span>
            </div>
            <div className="col-1">
              <DropDownAtividade
                onEdit={() => onEdit(atividade)}
                onDelete={() => onDelete(atividade)}
                onFinish={() => onFinish(atividade)}
                showFinish={atividade.conclusao == null}
                togglerContent={<MdMoreVert />}
                canShow={canShowOptions}
              />
            </div>
          </div>
        </div>
        <NewTaskNotification />
      </div>
    );
  }
}
