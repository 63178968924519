import React from "react";
import Grow from "@material-ui/core/Grow";
import { FaSmile, FaSadTear } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

import * as api from "../../Services/apiClient";
import * as DateHelper from "../../Utils/DateHelper";
import * as FiltroHelper from "../../Utils/FiltroHelper";
import LocalStorage from "../../Utils/LocalStorage";
import * as constants from "../../constants";

import Aviso from "../Aviso";
import ActOnFilterChange from "../ActOnFilterChange";
import ActionsOverlay from "./ActionsOverlay";
import ResumoAtividades from "./ResumoAtividades";
import CardAtividade from "./CardAtividade";
import LineAtividade from "./LineAtividade";
import CardIntervalo from "./CardIntervalo";
import ModalConfirmarRemocao from "./ModalConfirmarRemocao";
import ModalAtividade from "./ModalAtividade";
import ModalImportacao from "./ModalImportacao";
import ModalAvisoRelatorio from "./ModalAvisoRelatorio";

export default class Atividades extends ActOnFilterChange {
  state = {
    atividades: [],
    deletandoAtividade: false,
    atividadeSelecionada: undefined,
    mostrarModalAtividade: false,
    modalAvisoRelatorio: false,
    modalImportacaoAtividades: false,
    activeView: constants.ViewsAtividades.card
  };

  setAtividades = atividades => {
    this.setState({
      atividades: atividades,
      isLoading: false
    });
  };

  doActOnFilterChange = (codigoUsuario, filtro) => {
    api
      .get(`/atividade/${codigoUsuario}/${filtro}`)
      .then(atividades => {
        this.setAtividades(atividades);
      })
      .catch(error => {
        this.setAtividades([]);
        this.props.onError(
          error.message || "Ocorreu um erro ao buscar as atividades",
          error
        );
        this.setState({
          isLoading: false
        });
      });
  };

  exportar = () => {
    api
      .exportar(this.props.usuarioAtividade, this.props.filtro)
      .then(() => {
        this.setState({
          modalAvisoRelatorio: true
        });
      })
      .catch(error => {
        this.props.onError("Ocorreu um erro ao exportar as atividades", error);
      });
  };

  iniciarAtividade = () => {
    const inicio = DateHelper.getDateFormatted(
      null,
      DateHelper.API_DATE_TIME_FORMAT
    );
    const codigoCategoria = this.props.options.codigo_categoria_padrao;
    const codigoGE = this.props.options.codigo_grupo_empresa_padrao;
    const atividadeAtual = this.state.atividades.filter(
      a => a.conclusao === null
    )[0];
    if (atividadeAtual) {
      const result = DateHelper.validarDatasAtividade(
        atividadeAtual.inicio,
        DateHelper.getDate()
      );
      if (!result.isValid) {
        this.props.onError(
          "Não é possível criar uma atividade nova pois existe uma atividade corrente iniciada em data retroativa."
        );
        return;
      }
    }

    api
      .criarAtividade({
        inicio,
        codigo_departamento: this.props.options.codigo_departamento_padrao,
        codigo_categoria: codigoCategoria,
        codigo_grupo_empresa: codigoGE
      })
      .then(response => {
        response = {
          ...response,
          isNew: true
        };
        this.incluirAtividade(response);
        this.props.onAtividadeIniciada();
      })
      .catch(error => {
        this.props.onError(
          error.message || "Ocorreu um erro ao iniciar a atividade",
          error
        );
        this.setState({
          isLoading: false
        });
      });
  };

  incluirAtividade = novaAtividade => {
    //if filtro certo, adiciona na lista
    let atividades = this.obterListaAtividadesPosFinalizacao(
      novaAtividade.inicio
    );

    this.setState({
      atividades: [novaAtividade].concat(atividades)
    });

    setTimeout(() => {
      this.setState({
        atividades: this.state.atividades.map(item => {
          if (!item.isNew) {
            return item;
          }

          return {
            ...item,
            isNew: false
          };
        })
      });
    }, 5000);
  };

  removerAtividade = codigo => {
    this.setState({
      atividades: this.state.atividades.filter((item, i, a) => {
        return item.codigo !== codigo;
      }),
      atividadeSelecionada: undefined
    });
  };

  obterListaAtividadesPosFinalizacao = dataFinal => {
    let atividades = this.state.atividades;
    atividades = atividades.map(a => {
      if (a.conclusao !== null) return a;
      return {
        ...a,
        conclusao: dataFinal
      };
    });
    return atividades;
  };

  confirmarDeletarAtividade = atividade => {
    if (atividade) {
      this.setState({
        deletandoAtividade: true,
        atividadeSelecionada: atividade
      });
    }
  };

  deletarAtividade = codigo => {
    api
      .deletarAtividade(codigo)
      .then(() => {
        this.removerAtividade(codigo);
        toast.success("Atividade removida com sucesso!");
      })
      .catch(error => {
        this.props.onError("Ocorreu um erro ao deletar a atividade", error);
        this.setState({
          isLoading: false
        });
      });
  };

  iniciarEdicaoAtividade = atividade => {
    this.setState({
      mostrarModalAtividade: true,
      atividadeSelecionada: atividade
    });
  };

  editarAtividade = atividade => {
    const atividades = this.state.atividades.map(a => {
      if (a.codigo !== atividade.codigo) return a;
      return atividade;
    });

    this.setState({
      atividades: atividades
    });
  };

  terminarAtividade = atividade => {
    const conclusao = DateHelper.getDate();
    const result = DateHelper.validarDatasAtividade(
      atividade.inicio,
      conclusao
    );
    if (!result.isValid) {
      this.props.onError(
        result.message +
          " Finalize a atividade no mesmo dia em que ela começou e então crie outra(s) atividade(s) para contemplar o tempo total."
      );
      return;
    }
    const request = {
      ...atividade,
      conclusao: DateHelper.getDateFormatted(
        conclusao,
        DateHelper.API_DATE_TIME_FORMAT
      )
    };
    api
      .editarAtividade(atividade.codigo, request)
      .then(response => {
        this.finalizarAtividade(response);
        toast.success("Atividade finalizada com sucesso!");
      })
      .catch(error => {
        this.props.onError(
          error.message || "Ocorreu um erro ao finalizar a atividade",
          error
        );
      });
  };

  finalizarAtividade = atividade => {
    const atividades = this.state.atividades.map(a => {
      if (a.codigo !== atividade.codigo) return a;
      return {
        ...a,
        total: atividade.total,
        conclusao: atividade.conclusao
      };
    });

    this.setState({
      atividades: atividades
    });
  };

  resetarAtividadeSelecionada = () => {
    this.setState({
      atividadeSelecionada: undefined
    });
  };

  changeViewTo = view => {
    this.setState({
      ...this.state,
      activeView: view
    });
  };

  toggleModal = (key, shouldShow) => {
    this.setState({ [key]: shouldShow });
  };

  calcularIntervalo = (atividade, index, array) => {
    let atividadeAnterior = null;
    let intervalo = null;
    if (index < array.length - 1) {
      atividadeAnterior = array[index + 1];
      let inicio = DateHelper.parseDate(
        atividade.inicio,
        DateHelper.API_DATE_TIME_FORMAT
      );
      let fim = DateHelper.parseDate(
        atividadeAnterior.conclusao,
        DateHelper.API_DATE_TIME_FORMAT
      );
      if (DateHelper.isSameDay(inicio, fim))
        intervalo = DateHelper.dateDiff(fim, inicio);
    }
    return intervalo;
  };

  render() {
    const {
      atividades,
      isLoading,
      mostrarModalAtividade,
      modalAvisoRelatorio,
      deletandoAtividade,
      atividadeSelecionada,
      modalImportacaoAtividades,
      activeView
    } = this.state;
    const { now, filtro, options, usuarioAtividade } = this.props;
    const {
      departamentos,
      categorias,
      codigo_departamento_padrao,
      codigo_categoria_padrao,
      codigo_grupo_empresa_padrao,
      empresas
    } = options;
    const hasAtividades = atividades && atividades.length > 0;
    const isSameUser = usuarioAtividade === LocalStorage.usuario();

    return (
      <div className="has-navbar h-100">
        <ToastContainer
          autoClose={3500}
          toastClassName="mt-5"
          newestOnTop={true}
        />
        <ActionsOverlay
          isLoading={isLoading}
          hasAtividades={hasAtividades}
          iniciarAtividade={this.iniciarAtividade}
          iniciarImportacaoAtividades={() =>
            this.toggleModal("modalImportacaoAtividades", true)
          }
          iniciarCriacaoAtividade={() =>
            this.toggleModal("mostrarModalAtividade", true)
          }
        />
        {isLoading && <Aviso icone={<FaSmile />} mensagem={"Carregando..."} />}

        {!isLoading && (
          <>
            {!hasAtividades && (
              <Aviso
                icone={<FaSadTear />}
                mensagem={`Nenhuma atividade registrada no perí­odo de ${FiltroHelper.getStartDate(
                  filtro
                )} a ${FiltroHelper.getEndDate(filtro)}`}
              />
            )}
            {hasAtividades && (
              <div className="col-12 col-md-10 offset-md-1">
                <Grow in={true}>
                  <ResumoAtividades
                    atividades={atividades}
                    inicio={FiltroHelper.getStartDate(filtro)}
                    fim={FiltroHelper.getEndDate(filtro)}
                    changeViewTo={this.changeViewTo}
                    activeView={activeView}
                    exportar={this.exportar}
                  />
                </Grow>
                {atividades.map(
                  function(a, index, array) {
                    let delay = 20 * index;
                    let intervalo = this.calcularIntervalo(a, index, array);
                    let canShowIntervalo =
                      activeView === constants.ViewsAtividades.card;
                    let component = (
                      <CardAtividade
                        key={a.codigo}
                        atividade={a}
                        onEdit={this.iniciarEdicaoAtividade}
                        onDelete={this.confirmarDeletarAtividade}
                        onFinish={this.terminarAtividade}
                        now={now}
                        canShowOptions={isSameUser}
                      />
                    );
                    if (activeView === constants.ViewsAtividades.list)
                      component = (
                        <LineAtividade
                          key={a.codigo}
                          atividade={a}
                          onEdit={this.iniciarEdicaoAtividade}
                          onDelete={this.confirmarDeletarAtividade}
                          onFinish={this.terminarAtividade}
                          now={now}
                          canShowOptions={isSameUser}
                        />
                      );

                    return (
                      <Grow
                        in={true}
                        style={{ transitionDelay: delay + "ms" }}
                        key={a.codigo}
                      >
                        <>
                          {component}
                          {canShowIntervalo && (
                            <CardIntervalo intervalo={intervalo} />
                          )}
                        </>
                      </Grow>
                    );
                  }.bind(this)
                )}
              </div>
            )}
          </>
        )}
        <ModalAvisoRelatorio
          isOpen={modalAvisoRelatorio}
          onCancel={() => {
            this.toggleModal("modalAvisoRelatorio", false);
          }}
        />
        <ModalConfirmarRemocao
          isOpen={deletandoAtividade}
          onCancel={() => {
            this.toggleModal("deletandoAtividade", false);
            this.resetarAtividadeSelecionada();
          }}
          onConfirm={codigo => this.deletarAtividade(codigo)}
          atividade={atividadeSelecionada}
        />
        <ModalAtividade
          departamentos={departamentos}
          categorias={categorias}
          empresas={empresas}
          codigo_departamento_padrao={codigo_departamento_padrao}
          codigo_categoria_padrao={codigo_categoria_padrao}
          codigo_grupo_empresa_padrao={codigo_grupo_empresa_padrao}
          isOpen={mostrarModalAtividade}
          atividade={atividadeSelecionada}
          onCancel={() => {
            this.toggleModal("mostrarModalAtividade", false);
            this.resetarAtividadeSelecionada();
          }}
          onCreate={atividade => this.incluirAtividade(atividade)}
          onEdit={atividade => this.editarAtividade(atividade)}
        />
        <ModalImportacao
          onCancel={() => {
            this.toggleModal("modalImportacaoAtividades", false);
          }}
          onUploaded={() => {
            toast.success("Atividades importadas com sucesso!");
            this.toggleModal("modalImportacaoAtividades", false);
            this.act();
          }}
          isOpen={modalImportacaoAtividades}
        />
      </div>
    );
  }
}
