import React from "react";

const Aviso = props => {
  return (
    <div className="h-100">
      <div className="text-center lead p-3 pt-5">
        <div className="display-1 text-secondary">{props.icone}</div>
        <p className="m-2">{props.mensagem}</p>
      </div>
    </div>
  );
};

export default Aviso;
