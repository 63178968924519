import React from "react";
import "bootstrap/js/dist/dropdown";

import AcoesAtividade from "./AcoesAtividade";
const DropDownAtividade = props => {
  const {
    onDelete,
    onFinish,
    onEdit,
    showFinish,
    togglerContent,
    className
  } = props;
  const isContentString = typeof togglerContent === "string";
  return (
    <div className={`dropdown ` + className}>
      <button
        className={`btn ${
          isContentString
            ? "btn-light dropdown-toggle"
            : "btn-sm btn-link dropdown-toggle-split p-0"
        }`}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {togglerContent}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <AcoesAtividade
          onDelete={onDelete}
          onFinish={onFinish}
          onEdit={onEdit}
          showFinish={showFinish}
          inline={false}
        />
      </div>
    </div>
  );
};

export default DropDownAtividade;
