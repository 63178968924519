import React, { Component } from "react";
import { Redirect } from "react-router";
import { FaUser, FaKey } from "react-icons/fa";

import * as constants from "../../constants";
import * as api from "../../Services/apiClient";
import LocalStorage from "../../Utils/LocalStorage";

import { InputGroup } from "../../Components/Form";
import { Button } from "../../Components/Button";

export default class Login extends Component {
  state = {
    usuario: "",
    senha: "",
    errors: {
      usuario: null,
      senha: null
    },
    isLoading: false,
    errorLogin: null,
    isLogout: new URLSearchParams(this.props.location.search).get("logout")
  };

  componentWillMount() {
    if (LocalStorage.isLogged()) {
      this.setState({ isLogged: true });
    }

    if (this.state.isLogout) {
      setTimeout(() => {
        this.setState({
          isLogout: false
        });
      }, 2000);
    }
  }

  handleChange = event => {
    let errors = this.state.errors;
    errors[event.target.name] = event.target.value ? false : true;

    this.setState({
      errors: errors,
      [event.target.name]: event.target.value
    });
  };

  doLogin = e => {
    e.preventDefault();

    this.setState({
      errorLogin: null,
      isLoading: true,
      errors: {
        usuario: null,
        senha: null
      }
    });

    if (!this.state.usuario || !this.state.senha) {
      let errors = this.state.errors;
      errors.usuario = this.state.usuario ? false : true;
      errors.senha = this.state.senha ? false : true;

      this.setState({
        errors: errors
      });

      return;
    }

    api
      .doLogin({
        username: this.state.usuario,
        password: this.state.senha
      })
      .then(response => {
        LocalStorage.set(constants.AUTH_KEY, response.access_token);
        LocalStorage.set(constants.NOME_USUARIO, response.nome);
        LocalStorage.usuario(response.usuario);
        this.setState({
          isLoading: false,
          isLogged: true
        });
        if (this.props.handleLoginSuccess) this.props.handleLoginSuccess();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          errorLogin: "Usuário ou senha inválido",
          isLoading: false
        });
      });
  };

  render() {
    const { errors, errorLogin, isLoading, isLogged, isLogout } = this.state;

    if (isLogged) {
      return <Redirect to="/atividades" />;
    }

    return (
      <div className="Login d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="card my-5">
                <div className="card-body">
                  <h3 className="text-center">Login</h3>
                  {isLogout && (
                    <div className="alert alert-info">Você desconectou!</div>
                  )}
                  <form
                    noValidate
                    onSubmit={this.doLogin}
                    className="form-signin"
                  >
                    <InputGroup
                      autoFocus
                      placeholder="Seu usuário"
                      label="Usuário"
                      name="usuario"
                      invalid={errors && errors.usuario && "Campo obrigatório"}
                      required={true}
                      onChange={this.handleChange}
                      disabled={isLoading}
                      Icon={<FaUser />}
                    />
                    <InputGroup
                      type="password"
                      label="Senha"
                      name="senha"
                      invalid={errors && errors.senha && "Campo obrigatório"}
                      placeholder="Sua senha"
                      required={true}
                      disabled={isLoading}
                      onChange={this.handleChange}
                      Icon={<FaKey />}
                    />
                    <Button
                      textUpperCase
                      styleButton="primary"
                      className="btn-block"
                      label="Entrar"
                      isLoading={isLoading && "Autenticando..."}
                    />

                    {errorLogin && (
                      <div className="text-center text-danger mt-4">
                        {errorLogin}
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
