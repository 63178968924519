import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "../../Modal";

class ModalConfirmarRemocao extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    atividade: PropTypes.object
  };

  render() {
    const { isOpen, atividade, onConfirm, onCancel } = this.props;

    return (
      <Modal title="REMOVER ATIVIDADE" isOpen={isOpen} onClose={onCancel}>
        <p className="py-3">
          A atividade <strong>{atividade && atividade.Descricao}</strong> será
          removida, deseja continuar?
        </p>
        <div className="float-right">
          <button className="btn btn-primary mr-2" onClick={onCancel}>
            Cancelar
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              onCancel();
              onConfirm(atividade.codigo);
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
    );
  }
}

export default ModalConfirmarRemocao;
