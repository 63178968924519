import React, { Component } from "react";
import {
  FaPlus,
  FaPlay,
  FaCalendar,
  FaHandPointRight,
  FaFileImport
} from "react-icons/fa";

export default class ActionsOverlay extends Component {
  state = {
    isActive: false
  };

  toggle = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  render() {
    const { isActive } = this.state;
    const {
      iniciarAtividade,
      iniciarCriacaoAtividade,
      iniciarImportacaoAtividades,
      hasAtividades,
      isLoading
    } = this.props;

    return (
      <>
        {!isLoading && !hasAtividades && (
          <p className="slide fixed-bottom">
            Inicie uma atividade&nbsp;
            <FaHandPointRight className="h4" />
          </p>
        )}

        <div className="ActionsOverlay fixed-bottom">
          <div className={`actions ${isActive && "is-active"}`}>
            <button
              onClick={() => {
                iniciarImportacaoAtividades();
                this.toggle();
              }}
              type="button"
              className="btn btn-rounded btn-warning"
            >
              <span className="text-center">Importar</span>
              <FaFileImport />
            </button>
            <button
              onClick={() => {
                iniciarCriacaoAtividade();
                this.toggle();
              }}
              type="button"
              className="btn btn-rounded btn-info"
            >
              <span className="text-center">Nova Tarefa</span>
              <FaCalendar />
            </button>
            <button
              onClick={() => {
                iniciarAtividade();
                this.toggle();
              }}
              type="button"
              className="btn btn-rounded btn-success"
            >
              <span className="text-center">Iniciar</span>
              <FaPlay />
            </button>
            <button
              className="btn btn-rounded btn-primary"
              type="button"
              onClick={this.toggle}
            >
              <span className="text-center">Cancelar</span>
              <FaPlus />
            </button>
          </div>

          <div className="overlay" onClick={this.toggle}></div>
        </div>
      </>
    );
  }
}
