import React from "react";
import Collapse from "@material-ui/core/Collapse";
import { FaCalendarAlt } from "react-icons/fa";
import { isEqual } from "lodash/lang";

import PainelBuscar from "./PainelBuscar";
import Collapsable from "../Collapsable";
import { Select } from "../Form";

import * as FiltroHelper from "../../Utils/FiltroHelper";
import * as constants from "../../constants";

class Sidebar extends React.Component {
  state = {
    showBusca: false,
    usuarios: [],
    departamentos: [],
    usuariosDepartamento: [],
    form: {
      departamento: "",
      usuario: ""
    }
  };

  componentDidMount() {
    this.resetSelects();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.shouldResetUser !== this.props.shouldResetUser &&
      this.props.shouldResetUser
    ) {
      this.resetSelects();
      this.props.onUserReset();
      return;
    }
    if (
      prevProps.usuario === this.props.usuario &&
      isEqual(prevProps.options, this.props.options)
    ) {
      return;
    }
    this.resetSelects();
  }

  resetSelects = () => {
    const { options, usuario } = this.props;
    const { codigo_departamento_padrao } = options;
    let { departamentos } = options;

    departamentos = departamentos || [];
    const departamentosMapped = departamentos.map(
      constants.CodigoDescricaoMapper
    );

    const usuarios = departamentos.reduce((m, d) => {
      return m.concat(d.membros);
    }, []);

    const usuariosMapped = usuarios
      .filter(u => u.codigo_departamento === codigo_departamento_padrao)
      .map(v => ({
        value: v.codigo,
        label: v.nome
      }));

    this.setState({
      departamentos: departamentosMapped,
      usuariosDepartamento: usuariosMapped,
      usuarios: usuarios,
      form: {
        departamento: codigo_departamento_padrao,
        usuario: usuario
      }
    });
    this.props.onUsuarioAtividadeChanged(usuario);
  };

  togglePainelBusca = e => {
    this.setState({
      showBusca: !this.state.showBusca
    });
  };

  sendFilter = filtro => {
    if (FiltroHelper.FILTERS_DATE.hasOwnProperty(filtro))
      filtro = FiltroHelper.FILTERS_DATE[filtro];
    else filtro = FiltroHelper.FILTERS_DATE.hoje;
    this.props.onFilterChanged(filtro);
  };

  sendCustomFilter = (inicio, fim) => {
    let filtro = FiltroHelper.getFormattedFilter(inicio, fim);
    this.props.onFilterChanged(filtro);
  };

  handleChangeByName = (name, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  trocarDepartamento = event => {
    const codigoDepartamento = JSON.parse(event.value || "0");
    this.doTrocarDepartamento(codigoDepartamento);
    this.handleChangeByName("departamento", event.value);
  };

  doTrocarDepartamento = codigoDepartamento => {
    const usuariosDepartamento = this.state.usuarios
      .filter(u => u.codigo_departamento === codigoDepartamento)
      .map(v => ({
        value: v.codigo,
        label: v.nome
      }));
    this.setState({
      ...this.state,
      usuariosDepartamento: usuariosDepartamento
    });
  };

  trocarUsuarioAtividade = event => {
    this.handleChangeByName("usuario", event.value);
    this.props.onUsuarioAtividadeChanged(event.value);
  };

  render() {
    const { showBusca, departamentos, usuariosDepartamento, form } = this.state;
    const { departamento, usuario } = form;
    const { options, filtro } = this.props;
    const { tipo_usuario } = options;

    const isHoje = filtro === FiltroHelper.FILTERS_DATE.hoje;
    const isSemana = filtro === FiltroHelper.FILTERS_DATE.semana;
    const isMes = filtro === FiltroHelper.FILTERS_DATE.mes;
    const isMesRetroativo = filtro === FiltroHelper.FILTERS_DATE.mesRetroativo;
    const is7Dias = filtro === FiltroHelper.FILTERS_DATE.seteDias;
    const is30Dias = filtro === FiltroHelper.FILTERS_DATE.trintaDias;
    const isCustom =
      !isHoje &&
      !isSemana &&
      !isMes &&
      !isMesRetroativo &&
      !is7Dias &&
      !is30Dias;

    const departamentoValue = departamentos.filter(
      c => c.value === departamento
    )[0];
    const colaboradorValue = usuariosDepartamento.filter(
      e => e.value === usuario
    )[0];

    return (
      <nav className="navbar-expand-md sidebar">
        <Collapsable icon={<FaCalendarAlt />}>
          <ul className="nav flex-column mt-3">
            <li className={`nav-item ${isHoje && " active"}`}>
              <button
                className="nav-link btn btn-link w-100 text-left"
                onClick={() => this.sendFilter("hoje")}
              >
                Hoje
              </button>
            </li>
            <li className={`nav-item ${isSemana && " active"}`}>
              <button
                className="nav-link btn btn-link w-100 text-left"
                onClick={() => this.sendFilter("semana")}
              >
                Semana atual
              </button>
            </li>
            <li className={`nav-item ${isMes && " active"}`}>
              <button
                className="nav-link btn btn-link w-100 text-left"
                onClick={() => this.sendFilter("mes")}
              >
                Mês atual
              </button>
            </li>
            <li className={`nav-item ${isMesRetroativo && " active"}`}>
              <button
                className="nav-link btn btn-link w-100 text-left"
                onClick={() => this.sendFilter("mesRetroativo")}
              >
                Mês retroativo
              </button>
            </li>
            <li className={`nav-item ${is7Dias && " active"}`}>
              <button
                className="nav-link btn btn-link w-100 text-left"
                onClick={() => this.sendFilter("seteDias")}
              >
                7 dias
              </button>
            </li>
            <li className={`nav-item ${is30Dias && " active"}`}>
              <button
                className="nav-link btn btn-link w-100 text-left"
                onClick={() => this.sendFilter("trintaDias")}
              >
                30 dias
              </button>
            </li>
            <li className={`nav-item ${isCustom && " active"}`}>
              <button
                className="nav-link btn btn-link w-100 text-left"
                onClick={this.togglePainelBusca}
              >
                Personalizado
              </button>
            </li>
            <li>
              <Collapse in={showBusca}>
                <PainelBuscar
                  onFilterChanged={this.sendCustomFilter}
                  postSubmit={this.togglePainelBusca}
                />
              </Collapse>
            </li>
            {departamentos &&
              tipo_usuario === constants.TipoUsuario.Presidente && (
                <li className="pl-3">
                  <Select
                    label="Departamentos"
                    name="departamento"
                    options={departamentos}
                    value={departamentoValue}
                    onChange={this.trocarDepartamento}
                    disabled={tipo_usuario !== constants.TipoUsuario.Presidente}
                  />
                </li>
              )}
            {usuariosDepartamento &&
              tipo_usuario !== constants.TipoUsuario.Colaborador && (
                <li className="pl-3">
                  <Select
                    label="Colaboradores"
                    name="usuario"
                    options={usuariosDepartamento}
                    value={colaboradorValue}
                    onChange={this.trocarUsuarioAtividade}
                    disabled={
                      tipo_usuario === constants.TipoUsuario.Colaborador ||
                      departamento === ""
                    }
                  />
                </li>
              )}
          </ul>
        </Collapsable>
      </nav>
    );
  }
}

export default Sidebar;
