import React, { Component } from "react";
import PropTypes from "prop-types";

import LocalStorage from "../../Utils/LocalStorage";
import * as api from "../../Services/apiClient";

import Modal from "../Modal";
import { Select } from "../Form";

class ModalConfiguracoes extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func
  };

  state = {
    isSaving: false,
    categorias: [],
    form: {
      categoria: "",
      empresa: ""
    },
    error: null
  };

  componentDidUpdate = prevProps => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.setState({
        ...this.state,
        form: {
          categoria: this.props.options.codigo_categoria_padrao,
          empresa: this.props.options.codigo_grupo_empresa_padrao
        },
        error: null,
        isSaving: false
      });
    }
  };

  handleSelectorChange = (name, value) => {
    this.setState({
      error: null
    });

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  save = () => {
    const categoria = this.state.form.categoria;
    const empresa = this.state.form.empresa;
    this.setState({
      error: null,
      isSaving: true
    });

    const request = {
      codigo_grupo_empresa_padrao: empresa,
      codigo_categoria_padrao: categoria
    };
    api
      .atualizarOpcoes(LocalStorage.usuario(), request)
      .then(() => {
        this.props.onOptionsChanged(categoria, empresa);
        this.props.onCancel();
        this.setState({
          error: null,
          isSaving: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error: "Erro ao editar opções",
          isSaving: false
        });
      });
  };

  render() {
    const { isOpen, onCancel, options } = this.props;
    const { categorias, empresas } = options;
    const { form, error, isSaving } = this.state;
    const { categoria, empresa } = form;

    const categoriaValue = categorias.filter(c => c.value === categoria)[0];
    const empresaValue = empresas.filter(e => e.value === empresa)[0];

    return (
      <Modal title="CONFIGURAÇÕES" isOpen={isOpen} onClose={onCancel}>
        <>
          <Select
            label="Categoria padrão"
            name="categoria"
            options={categorias}
            value={categoriaValue || ""}
            onChange={e => this.handleSelectorChange("categoria", e.value)}
            disabled={isSaving}
          />
          <Select
            label="Empresa padrão"
            className="mb-4"
            name="empresa"
            options={empresas}
            value={empresaValue || ""}
            onChange={e => this.handleSelectorChange("empresa", e.value)}
            disabled={isSaving}
          />
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="float-right">
            <button
              disabled={isSaving}
              className="btn btn-primary"
              onClick={() => {
                this.save();
              }}
            >
              Salvar
            </button>
          </div>
        </>
      </Modal>
    );
  }
}

export default ModalConfiguracoes;
