import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { FaUserClock, FaList } from "react-icons/fa";

import * as constants from "../../constants";
import LocalStorage from "../../Utils/LocalStorage";
import Collapsable from "../Collapsable";

class Navbar extends Component {
  doLogout = () => {
    LocalStorage.clearAll();
    this.props.onLogout();
    //propriedade history adicionada pelo react-router ao Component
    this.props.history.push("/login?logout=true");
  };

  render() {
    const { options, showSettings, disabled } = this.props;

    const { tem_acesso_colaboradores } = options;
    return (
      <>
        <nav className="navbar navbar-tasks navbar-expand-md navbar-dark fixed-top bg-primary">
          <a className="navbar-brand align-middle" href="/">
            <div className="row align-items-center">
              <div className="col-2 mb-1">
                <FaUserClock />
              </div>
              <div className="col-9 text-truncate">Apontamento</div>
            </div>
          </a>
          <Collapsable icon={<FaList />}>
            <div className="navbar-nav mr-auto">
              <span className="navbar-text text-nowrap">
                {LocalStorage.get(constants.NOME_USUARIO)}
              </span>
            </div>

            <div className="navbar-nav">
              {tem_acesso_colaboradores && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/colaboradores">
                    Colaboradores
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                <NavLink className="nav-link" to="/atividades">
                  Atividades
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/dashboard">
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link border-0"
                  onClick={showSettings}
                  disabled={disabled}
                >
                  Configurações
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link border-0"
                  onClick={this.doLogout}
                >
                  Sair
                </button>
              </li>
            </div>
          </Collapsable>
        </nav>
      </>
    );
  }
}
export default withRouter(Navbar);
