import React from "react";
import { Route, Redirect } from "react-router-dom";

import LocalStorage from "../../Utils/LocalStorage";
import Navbar from "../Navbar";

const PrivateRoute = ({ component: Component, hasNavbar, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const isLogged = LocalStorage.isLogged();
        if (!isLogged) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }

        if (hasNavbar) {
          return (
            <>
              <Navbar
                disabled={rest.isLoading}
                showSettings={rest.toggleSettings}
                options={rest.options}
                onLogout={rest.onLogout}
              />
              <Component {...rest} />;
            </>
          );
        }

        return <Component {...rest} />;
      }}
    />
  );
};

export default PrivateRoute;
