import React, { Component } from "react";
import PropTypes from "prop-types";
import Zoom from "@material-ui/core/Zoom";

export default class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    isOpen: false
  };

  render() {
    const { children, title, isOpen, onClose, ...rest } = this.props;
    return (
      <Zoom in={isOpen}>
        <div
          {...rest}
          className={`modal fade ${isOpen && "show d-block"} ${isOpen &&
            "modal-open"}`}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {title && (
                  <h5 className="modal-title" id="exampleModalLabel">
                    {title}
                  </h5>
                )}
                <button type="button" className="close" onClick={onClose}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">{children}</div>
            </div>
          </div>
        </div>
      </Zoom>
    );
  }
}
