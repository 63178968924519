import React from "react";
import { MdViewHeadline, MdViewDay } from "react-icons/md";
import { FaFileExcel } from "react-icons/fa";
import Divider from "@material-ui/core/Divider";

import * as DateHelper from "../../Utils/DateHelper";
import * as constants from "../../constants";

const ResumoAtividades = props => {
  const {
    atividades,
    inicio,
    fim,
    changeViewTo,
    activeView,
    exportar,
    ...rest
  } = props;
  const isCard = activeView === constants.ViewsAtividades.card;

  const totalHoras = calcularTotalDeHoras(atividades);
  return (
    <div className="row" {...rest}>
      <div className="col-12 mb-3 mt-3">
        <div className="card w-100 border border-primary">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-8">
                <span className="text-muted">
                  Mostrando atividades do período de {inicio} a {fim}
                </span>
              </div>
              <div className="col-12 col-lg-4">
                <span className="text-primary float-lg-right text-right">
                  <strong>
                    {atividades.length}{" "}
                    {atividades.length === 1 ? "atividade" : "atividades"}
                    <span>
                      &nbsp;|&nbsp;Total:{" "}
                      {DateHelper.getDurationFormatted(totalHoras)}
                    </span>
                  </strong>
                </span>
              </div>
            </div>
            <Divider light variant="middle" className="m-3" />
            <div className="row">
              <div className="col-6">
                <button
                  className={`btn btn-primary btn-sm mr-2`}
                  onClick={exportar}
                >
                  <FaFileExcel />
                </button>
              </div>
              <div className="col-6">
                <div className="float-right">
                  <button
                    className={`btn ${
                      isCard ? "btn-primary" : "btn-outline-primary"
                    } btn-sm mr-2`}
                    onClick={() => changeViewTo(constants.ViewsAtividades.card)}
                  >
                    <MdViewDay />
                  </button>
                  <button
                    className={`btn ${
                      isCard ? "btn-outline-primary" : "btn-primary"
                    } btn-sm`}
                    onClick={() => changeViewTo(constants.ViewsAtividades.list)}
                  >
                    <MdViewHeadline />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const calcularTotalDeHoras = atividades => {
  let totalHoras = 0;
  if (atividades) {
    totalHoras = atividades.reduce((prev, curr) => {
      const total = DateHelper.dateDiff(curr.inicio, curr.conclusao);
      if (prev) return prev + total;
      return total;
    }, null);
  }
  return totalHoras;
};

export default ResumoAtividades;
