import React from "react";

import * as DateHelper from "../../Utils/DateHelper";

const ts = require("timespan");
const CINCO_MINUTOS = 5 * 60 * 1000;

const CardIntervalo = props => {
  const { intervalo } = props;

  if (intervalo == null || intervalo < CINCO_MINUTOS) return null;

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card">
          <div className="card-header">
            <p className="card-text text-muted m-0">
              Intervalo:{" "}
              {DateHelper.formatTimeSpan(ts.fromMilliseconds(intervalo))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardIntervalo;
