import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChartistGraph from "react-chartist";

import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";

import * as DSOptions from "../dashboardOptions";

import styles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const LineChart = props => {
  const classes = useStyles();
  const { type, title, series, labels } = props;

  return (
    <Card chart>
      <CardHeader color={type}>
        {labels.length > 0 && (
          <ChartistGraph
            className="ct-chart"
            data={{ series: series, labels: labels }}
            type="Line"
            listener={DSOptions.animation}
          />
        )}
        {labels.length === 0 && (
          <p className="py-5">Não há dados para exibir</p>
        )}
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>{title}</h4>
      </CardBody>
    </Card>
  );
};

export default LineChart;
