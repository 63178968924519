import * as constants from "../constants";
import LocalStorage from "../Utils/LocalStorage";

// Handle HTTP errors since fetch won't.
const handleErrors = async response => {
  if (response.status === 401) {
    LocalStorage.clearAll();
    window.location = "/login"; //TODO
  }
  if (!response.ok) {
    const data = await response.text();
    if (data) throw JSON.parse(data);
    throw response;
  }
  return response;
};

const baseFetch = (url, request = {}) => {
  let accessToken = LocalStorage.get(constants.AUTH_KEY)
    ? "Bearer " + LocalStorage.get(constants.AUTH_KEY)
    : null;

  let headers = {
    ...constants.ENV_VARS,
    ...request.headers,
    Authorization: accessToken
  };

  return fetch(constants.APIBASE + url, {
    ...request,
    headers
  }).then(handleErrors);
};

const getBlob = url => {
  return baseFetch(url, {})
    .then(async res => {
      const header = res.headers.get("Content-Disposition");
      const filename = header.match(/filename=(.+)/)[1];
      return {
        blob: await res.blob(),
        fileName: filename
      };
    })
    .then(data => {
      saveData(data.blob, data.fileName);
    });
};

const saveData = (blob, fileName) => {
  //https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
};
const doFetch = (url, request = {}) => {
  return baseFetch(url, request)
    .then(res => res.text())
    .then(data => (data ? JSON.parse(data) : {}));
};

export const get = doFetch;

const post = (url, body, headers = {}) =>
  doFetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      ...headers,
      "Content-type": "application/json"
    }
  });

const postFile = (url, file, headers = {}) => {
  const formData = new FormData();
  formData.append("file", file);

  return doFetch(url, {
    method: "POST",
    body: formData,
    headers: {
      ...headers
    }
  });
};

export const doLogin = credentials => {
  let params = {
    ...constants.ENV_VARS,
    ...credentials,
    grant_type: "password"
  };

  let searchParams = Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");

  return doFetch("/usuario/login", {
    method: "POST",
    body: searchParams,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

export const BASE_ATIVIDADES = "/atividade";

export const getOpcoes = () => get(BASE_ATIVIDADES + "/opcoes");

export const criarAtividade = body => post(BASE_ATIVIDADES, body);

export const editarAtividade = (codigo, body) =>
  post(BASE_ATIVIDADES + "/" + codigo, body);

export const deletarAtividade = codigo =>
  get(BASE_ATIVIDADES + "/" + codigo + "/deletar");

export const exportar = (codigUsuario, filtro) =>
  getBlob(BASE_ATIVIDADES + `/exportar/${codigUsuario}/${filtro}`);

export const atualizarOpcoes = (codigoUsuario, body) =>
  post(BASE_ATIVIDADES + "/" + codigoUsuario + "/opcoes", body);

export const buscarValoresHorasUsuarios = () =>
  get(BASE_ATIVIDADES + "/valor-hora");

export const inserirOuAtualizarValorHora = data =>
  post(BASE_ATIVIDADES + "/valor-hora", data);

export const uploadAtividades = file =>
  postFile(BASE_ATIVIDADES + "/importar", file);
