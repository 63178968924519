import React, { Component } from "react";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import * as api from "../../../Services/apiClient";
import * as constants from "../../../constants";
import * as DateHelper from "../../../Utils/DateHelper";

import Modal from "../../Modal";
import { Select, InputGroup } from "../../Form";
import { Button } from "../../Button";

class ModalValorHora extends Component {
  INITIAL_FORM = {
    codigo_usuario: null,
    valor_hora: null,
    porcentagem_fundos: null,
    porcentagem_ferias: null,
    porcentagem_decimo_terceiro: null,
    data_inicio_vigencia: null,
    data_fim_vigencia: null,
    tipo_calculo_hora: 1
  };
  INITIAL_STATE = {
    colaboradores: [],
    form: this.INITIAL_FORM,
    isSaving: false,
    error: null
  };
  state = this.INITIAL_STATE;
  static propTypes = {
    isOpen: PropTypes.bool,
    onCreate: PropTypes.func,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func
  };

  handleFloatChange = event => {
    this.setState({
      error: null
    });

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [event.target.name]: parseFloat(event.target.value)
      }
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: date
      }
    });
  };

  inserirOuAtualizarValorHora = e => {
    e.preventDefault(); //evita enviar o submit padrão da pagina

    this.setState({
      error: null,
      isLoading: true
    });

    const { form } = this.state;
    const { data_inicio_vigencia, data_fim_vigencia } = form;

    const validacao = DateHelper.validarDatasValorHora(
      data_inicio_vigencia,
      data_fim_vigencia,
      this.props.criandoRetroativo
    );

    if (!validacao.isValid) {
      this.setState({
        error: validacao.message,
        isLoading: false
      });
      return;
    }

    const request = {
      codigo_usuario: form.codigo_usuario,
      valor_hora: parseFloat(form.valor_hora),
      porcentagem_fundos: form.porcentagem_fundos
        ? parseFloat(form.porcentagem_fundos)
        : null,
      porcentagem_ferias: form.porcentagem_ferias
        ? parseFloat(form.porcentagem_ferias)
        : null,
      porcentagem_decimo_terceiro: form.porcentagem_decimo_terceiro
        ? parseFloat(form.porcentagem_decimo_terceiro)
        : null,
      tipo_calculo_hora: form.tipo_calculo_hora,
      data_inicio_vigencia: form.data_inicio_vigencia,
      data_fim_vigencia: form.data_fim_vigencia
    };

    api
      .inserirOuAtualizarValorHora(request)
      .then(response => {
        this.setState({
          isLoading: false,
          form: this.INITIAL_FORM
        });
        if (this.isEditing) this.props.onEdit(response);
        else {
          this.props.onCreate(response);
        }

        this.onCancel();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error:
            error.message ||
            `Erro ao ${this.isEditing ? "editar" : "criar"} o valor da hora`,
          isLoading: false
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen) {
      this.setState(this.INITIAL_STATE);

      const avh = this.props.atividadeValorHora;
      this.isEditing = avh !== null && avh !== undefined;
      if (this.isEditing) {
        this.setState({
          form: { ...avh, data_inicio_vigencia: null, data_fim_vigencia: null }
        });
      }
    }
  }

  onCancel = () => {
    this.props.onCancel();
    this.setState({
      form: this.INITIAL_FORM,
      error: null
    });
    this.isEditing = false;
  };

  handleSelectorChange = (name, value) => {
    this.setState({
      error: null
    });

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  render() {
    const { colaboradores, isOpen, criandoRetroativo } = this.props;
    const { form, isSaving, isLoading, error } = this.state;
    const {
      codigo_usuario,
      valor_hora,
      porcentagem_fundos,
      porcentagem_ferias,
      porcentagem_decimo_terceiro,
      tipo_calculo_hora,
      data_inicio_vigencia,
      data_fim_vigencia
    } = form;

    const comRetencao = tipo_calculo_hora === 0;

    const somaPorcentagens =
      (porcentagem_fundos || 0) +
      (porcentagem_ferias || 0) +
      (porcentagem_decimo_terceiro || 0);

    const canSubmit =
      codigo_usuario &&
      valor_hora &&
      (!comRetencao || (somaPorcentagens > 0 && somaPorcentagens <= 100.0));

    const tipoCalculoHoraValue = constants.TiposCalculoHora.filter(
      c => c.value === tipo_calculo_hora
    )[0];
    const colaboradorValue = colaboradores.filter(
      e => e.value === codigo_usuario
    )[0];

    return (
      <Modal
        title={
          criandoRetroativo
            ? "VALOR HORA RETROATIVO"
            : this.isEditing
            ? "EDITAR VALOR HORA"
            : "NOVO VALOR HORA"
        }
        isOpen={isOpen}
        onClose={this.onCancel}
      >
        <form noValidate onSubmit={this.inserirOuAtualizarValorHora}>
          {this.isEditing && (
            <small className="d-block text-muted">
              Vigência: {DateHelper.formatDateTimeBR(data_inicio_vigencia)} até
              a data atual
            </small>
          )}
          <Select
            label="Colaborador"
            name="codigo_usuario"
            options={colaboradores}
            value={colaboradorValue}
            onChange={e => this.handleSelectorChange("codigo_usuario", e.value)}
            disabled={isLoading}
          />
          {criandoRetroativo && (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                clearable
                clearLabel="limpar"
                className="pb-2"
                showTodayButton={true}
                todayLabel="agora"
                cancelLabel="cancelar"
                invalidDateMessage="Data inválida"
                ampm={false}
                label="Início vigência"
                inputVariant="outlined"
                name="data_inicio_vigencia"
                value={data_inicio_vigencia}
                onChange={date =>
                  this.handleDateChange("data_inicio_vigencia", date)
                }
                format={DateHelper.DEFAULT_FORMAT}
              />
              <KeyboardDatePicker
                clearable
                clearLabel="limpar"
                className="pb-2"
                showTodayButton={true}
                todayLabel="agora"
                cancelLabel="cancelar"
                invalidDateMessage="Data inválida"
                ampm={false}
                label="Fim vigência"
                inputVariant="outlined"
                name="data_fim_vigencia"
                value={data_fim_vigencia}
                onChange={date =>
                  this.handleDateChange("data_fim_vigencia", date)
                }
                format={DateHelper.DEFAULT_FORMAT}
              />
            </MuiPickersUtilsProvider>
          )}
          <InputGroup
            Icon="R$"
            label="Valor por Hora"
            name="valor_hora"
            type="number"
            step="0.01"
            placeholder={`Valor ${comRetencao ? "bruto" : "líquido"} por hora`}
            disabled={isLoading}
            value={valor_hora || ""}
            onChange={this.handleFloatChange}
          />
          <Select
            label="Tipo Cálculo de Hora"
            name="tipo_calculo_hora"
            options={constants.TiposCalculoHora}
            value={tipoCalculoHoraValue}
            onChange={e =>
              this.handleSelectorChange("tipo_calculo_hora", e.value)
            }
            disabled={true}
          />

          {comRetencao && (
            <>
              <InputGroup
                Icon="%"
                label="Porcentagem de Acerto"
                name="porcentagem_fundos"
                type="number"
                placeholder="Retida para acerto (0% a 99%)"
                min="0"
                max="99"
                length={2}
                disabled={isLoading}
                value={porcentagem_fundos || 0}
                onChange={this.handleFloatChange}
              />
              <InputGroup
                Icon="%"
                label="Porcentagem de Férias"
                name="porcentagem_ferias"
                type="number"
                placeholder="Retida para férias (0% a 99%)"
                min="0"
                max="99"
                length={2}
                disabled={isLoading}
                value={porcentagem_ferias || 0}
                onChange={this.handleFloatChange}
              />
              <InputGroup
                Icon="%"
                label="Porcentagem de 13º"
                name="porcentagem_decimo_terceiro"
                type="number"
                placeholder="Retida 13º salário (0% a 99%)"
                min="0"
                max="99"
                length={2}
                disabled={isLoading}
                value={porcentagem_decimo_terceiro || 0}
                onChange={this.handleFloatChange}
              />
            </>
          )}

          {error && <div className="alert alert-danger">{error}</div>}
          <Button
            label={
              criandoRetroativo
                ? "CRIAR VALOR HORA RETROATIVO"
                : this.isEditing
                ? "INATIVAR ANTERIOR E CRIAR NOVO"
                : "Criar Valores"
            }
            styleButton={
              this.isEditing || criandoRetroativo ? "danger" : "primary"
            }
            className="float-right"
            isLoading={isSaving && "Criando..."}
            disabled={isLoading || !canSubmit || isSaving}
          />
        </form>
      </Modal>
    );
  }
}

export default ModalValorHora;
