import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (!this.state.errorInfo) return this.props.children;

    return (
      <div className="has-navbar">
        <h2 className="text-danger">Um erro ocorreu.</h2>
        <details>
          {this.state.error && this.state.error.toString()}
          <br />
          {this.state.errorInfo.componentStack}
        </details>
      </div>
    );
  }
}

export default ErrorBoundary;
