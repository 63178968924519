import React from "react";
import "bootstrap/js/dist/dropdown";
import { FaPen, FaStop, FaTrash } from "react-icons/fa";

const AcoesAtividade = props => {
  const { onDelete, onFinish, onEdit, showFinish, inline, className } = props;
  return (
    <div className={className}>
      <button
        type="button"
        className={`btn ${inline ? "" : "dropdown-item"}`}
        onClick={onDelete}
      >
        <div className="text-danger">
          <FaTrash />
          &nbsp;Remover
        </div>
      </button>
      <button
        type="button"
        className={`btn ${inline ? "" : "dropdown-item"}`}
        onClick={onEdit}
      >
        <div className="text-info">
          <FaPen />
          &nbsp;Editar
        </div>
      </button>
      {showFinish && (
        <button
          type="button"
          className={`btn ${inline ? "" : "dropdown-item"}`}
          onClick={onFinish}
        >
          <div className="text-dark">
            <FaStop />
            &nbsp;Terminar
          </div>
        </button>
      )}
    </div>
  );
};

export default AcoesAtividade;
