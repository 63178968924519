import React from "react";
import Atividades from "../../Components/Atividades";
import TickContainer from "../../Components/TickContainer";

const AtividadesRoot = props => {
  const { sidebar, ...rest } = props;
  return (
    <>
      <div className="col-md-2 px-0">{sidebar}</div>
      <div className="col-md-10">
        <TickContainer>
          <Atividades {...rest} />
        </TickContainer>
      </div>
    </>
  );
};

export default AtividadesRoot;
