import React from "react";
import PropTypes from "prop-types";
import { default as Selector } from "react-select";

export const Field = ({
  children,
  label,
  name,
  invalid,
  required = false,
  help = null,
  isTextArea = false
}) => {
  children = {
    ...children,
    props: {
      ...children.props,
      className: invalid
        ? `is-invalid ${children.props.className}`
        : children.props.className
    }
  };
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name}>
          <span dangerouslySetInnerHTML={{ __html: label }} />
          {(children.props.required || required) && (
            <sup className="text-danger">*</sup>
          )}
        </label>
      )}
      {children}
      {children.props.maxLength && isTextArea && (
        <small className="form-text text-muted">
          {children.props.defaultValue ? children.props.defaultValue.length : 0}
          /{children.props.maxLength} caracteres
        </small>
      )}
      {help && !invalid && (
        <small className="form-text text-muted">{help}</small>
      )}
      {invalid && <div className="invalid-feedback">{invalid}</div>}
    </div>
  );
};
Field.propTypes = {
  name: PropTypes.string.isRequired,
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  help: PropTypes.string,
  helpClass: PropTypes.string
};

const Input = ({ type, invalid, ...rest }) => (
  <input
    type={type ? type : "text"}
    {...rest}
    className={`form-control ${invalid && "is-invalid"}`}
  />
);

export const TextInput = ({ ...rest }) => {
  return (
    <Field {...rest}>
      <Input {...rest} />
    </Field>
  );
};

TextInput.propTypes = {
  ...Field.PropTypes,
  placeholder: PropTypes.string,
  id: PropTypes.string
};

export const InputGroup = ({ Icon = null, Button = null, ...rest }) => (
  <Field {...rest}>
    <div className="input-group">
      {Icon && (
        <div className="input-group-prepend">
          <div className="input-group-text">{Icon}</div>
        </div>
      )}
      <Input {...rest} />
      {Button}
    </div>
  </Field>
);
InputGroup.propTypes = {
  ...TextInput.propTypes,
  Icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export const Select = ({
  options,
  defaultLabel = "Selecione uma opção",
  label,
  name,
  ...rest
}) => (
  <Field label={label} name={name}>
    <Selector {...rest} options={options} placeholder={defaultLabel}></Selector>
  </Field>
);
Select.propTypes = {
  ...Field.propTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string
    })
  ).isRequired,
  defaultLabel: PropTypes.string
};

export const TextAreaInput = ({ name, invalid, rows = 3, ...rest }) => (
  <Field isTextArea={true} name={name} {...rest}>
    <textarea
      name={name}
      id={name}
      rows={rows}
      className={`form-control ${invalid && "is-invalid"}`}
      {...rest}
    />
  </Field>
);
TextAreaInput.propTypes = {
  ...Field.propTypes
};
