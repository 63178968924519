import React from "react";
import { MdWatchLater } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

import Aviso from "../../Components/Aviso";
import * as api from "../../Services/apiClient";
import * as DateHelper from "../../Utils/DateHelper";
import * as constants from "../..//constants";
import ModalValorHora from "../../Components/Colaboradores/ModalValorHora";

export default class Colaboradores extends React.Component {
  state = {
    usuarios_horas: [],
    valorHoraSelecionado: null,
    mostrarModalValorHora: false,
    criandoRetroativo: false
  };

  resetarValorHoraSelecionado = () => {
    this.setState({
      valorHoraSelecionado: null
    });
  };

  componentDidMount() {
    this.setState({ isLoading: true });

    api
      .buscarValoresHorasUsuarios()
      .then(response => {
        this.setState({
          usuarios_horas: response.sort(constants.OrderByUsuarioNome),
          isLoading: false
        });
      })
      .catch(error => {
        this.props.onError(
          "Um erro ocorreu ao buscar as horas dos colaboradores",
          error
        );
        this.setState({
          isLoading: false
        });
      });
  }

  toggleModal = shouldShow => {
    this.setState({ mostrarModalValorHora: shouldShow });
  };

  abrirModalValorHora = (codigo_usuario, criandoRetroativo = false) => {
    this.setState({
      valorHoraSelecionado: null,
      criandoRetroativo: criandoRetroativo
    });
    if (codigo_usuario) {
      const avh = this.state.usuarios_horas.filter(
        v => v.codigo_usuario === codigo_usuario
      )[0];
      this.setState({
        valorHoraSelecionado: avh,
        criandoRetroativo: false
      });
    }
    this.toggleModal(true);
  };

  onCreate = avh => {
    if (!avh) return;
    let usuarios_horas = this.state.usuarios_horas.filter(
      e => e.codigo_usuario !== avh.codigo_usuario
    );
    usuarios_horas.push(avh);
    this.setState({
      usuarios_horas: usuarios_horas.sort(constants.OrderByUsuarioNome)
    });
    toast.success("Valor de hora criado com sucesso!", {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  onEdit = avh => {
    let usuarios_horas = this.state.usuarios_horas.map(existente => {
      if (existente.codigo_usuario !== avh.codigo_usuario) return existente;
      avh.showHistory = existente.showHistory || false;
      return avh;
    });
    this.setState({
      usuarios_horas: usuarios_horas.sort(constants.OrderByUsuarioNome)
    });
    toast.success("Valor de hora editado com sucesso!", {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  toggleHistorico = index => {
    const usuarios_horas = this.state.usuarios_horas;
    const avh = usuarios_horas[index];
    avh.showHistory = !avh.showHistory;
    this.setState({
      usuarios_horas: usuarios_horas
    });
  };

  showValueOrDash = value => {
    return value ? value.toFixed(2) : "-";
  };

  render() {
    const { colaboradores } = this.props.options;
    const {
      isLoading,
      usuarios_horas,
      mostrarModalValorHora,
      valorHoraSelecionado,
      criandoRetroativo
    } = this.state;
    return (
      <>
        <ToastContainer
          autoClose={3500}
          toastClassName="mt-5"
          newestOnTop={true}
        />

        <div
          className="has-navbar-no-sidebar row mx-0 mt-2 w-100 bg-white"
          style={{ position: "fixed", zIndex: 2 }}
        >
          <div className="col-12 col-sm-8">
            <h5 className="text-no-wrap text-truncate">
              Valores de horas dos colaboradores
            </h5>
          </div>
          <div className="col-12 col-sm-4">
            <button
              className="btn btn-primary float-right mb-2"
              disabled={isLoading}
              onClick={() => this.abrirModalValorHora(null)}
            >
              NOVO
            </button>
            <button
              className="btn btn-danger float-right mr-2 mb-2"
              disabled={isLoading}
              onClick={() => this.abrirModalValorHora(null, true)}
            >
              CRIAR RETROATIVO
            </button>
          </div>
        </div>

        <div className="has-navbar-no-sidebar mt-4 px-2 px-sm-5 w-100">
          <div className="mt-5">
            {isLoading && (
              <Aviso
                mensagem={"Carregando as horas dos colaboradores..."}
                icone={<MdWatchLater />}
              />
            )}
            {!isLoading &&
              usuarios_horas.map((uh, index) => {
                const somaPorcentagens =
                  uh.tipo_calculo_hora === 0
                    ? (uh.porcentagem_fundos || 0) +
                      (uh.porcentagem_decimo_terceiro || 0) +
                      (uh.porcentagem_ferias || 0)
                    : null;
                return (
                  <div
                    className="row bg-white p-2 mb-2 mx-2 mx-sm-5 border border-primary rounded"
                    key={uh.codigo_usuario}
                  >
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-sm-4 border-right">
                          <h5 className="mb-0">{uh.usuario.nome}</h5>
                          <small className="d-block">
                            Vigência a partir de:{" "}
                            {DateHelper.formatDateBR(uh.data_inicio_vigencia)}
                          </small>
                          <small>
                            Criado por {uh.usuario_criacao.nome} em{" "}
                            {DateHelper.formatDateTimeBR(uh.data_criacao)}
                          </small>
                        </div>
                        <div className="col-12 col-sm-3">
                          <small>Valor/Hora</small>
                          <h5> R$ {uh.valor_hora.toFixed(2)}</h5>
                        </div>
                        {uh.tipo_calculo_hora === 0 && (
                          <div className="col-12 col-sm-5">
                            <small className="d-block">Retenções</small>
                            <div className="row">
                              <div className="col-4">
                                <span className="mr-2 d-block">
                                  {this.showValueOrDash(uh.porcentagem_fundos)}%
                                  Acerto
                                </span>
                                <span className="mr-2 d-block">
                                  {this.showValueOrDash(uh.porcentagem_ferias)}%
                                  Férias
                                </span>
                                <span className="mr-2 d-block">
                                  {this.showValueOrDash(
                                    uh.porcentagem_decimo_terceiro
                                  )}
                                  % 13º
                                </span>
                              </div>
                              <div className="col-8">
                                <span className="h5">
                                  Total:{" "}
                                  {this.showValueOrDash(somaPorcentagens)}%
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-8">
                          {uh.historico.length > 0 && (
                            <small
                              className="text-info btn-toggler align-middle"
                              onClick={() => this.toggleHistorico(index)}
                            >
                              {uh.showHistory
                                ? "Esconder histórico"
                                : "Mostrar histórico"}
                            </small>
                          )}
                        </div>
                        <div className="col-4">
                          <button
                            className="btn btn-link float-right"
                            onClick={() =>
                              this.abrirModalValorHora(uh.codigo_usuario)
                            }
                          >
                            <FaPen />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {uh.showHistory &&
                        uh.historico.map(h => (
                          <div className="row p-2 m-2 bg-light border border-primary border-dotted">
                            <div className="col-12 col-sm-6">
                              <small className="d-block">
                                Vigência:
                                {DateHelper.formatDateBR(
                                  h.data_inicio_vigencia
                                )}{" "}
                                a {DateHelper.formatDateBR(h.data_fim_vigencia)}
                              </small>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="float-sm-right">
                                <small className="d-block">
                                  Criado por {h.usuario_criacao.nome} em{" "}
                                  {DateHelper.formatDateTimeBR(h.data_criacao)}
                                </small>
                                <small className="d-block">
                                  Inativado por {h.usuario_inativacao.nome} em{" "}
                                  {DateHelper.formatDateTimeBR(
                                    h.data_inativacao
                                  )}
                                </small>
                              </div>
                            </div>

                            <div className="col-12">
                              <span className="mr-1">
                                R$ {h.valor_hora.toFixed(2)}/hora
                              </span>
                              {h.tipo_calculo_hora === 0 && (
                                <>
                                  <span className="mr-1">|</span>
                                  <span className="mr-1">
                                    Acerto:{" "}
                                    {this.showValueOrDash(h.porcentagem_fundos)}
                                    %
                                  </span>
                                  <span className="mr-1">
                                    Férias:{" "}
                                    {this.showValueOrDash(h.porcentagem_ferias)}
                                    %
                                  </span>
                                  <span className="mr-1">
                                    13º:{" "}
                                    {this.showValueOrDash(
                                      h.porcentagem_decimo_terceiro
                                    )}
                                    %
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
          </div>
          <ModalValorHora
            isOpen={mostrarModalValorHora}
            atividadeValorHora={valorHoraSelecionado}
            criandoRetroativo={criandoRetroativo}
            onCancel={() => {
              this.toggleModal(false);
              this.resetarValorHoraSelecionado();
            }}
            colaboradores={colaboradores}
            onCreate={avh => this.onCreate(avh)}
            onEdit={avh => this.onEdit(avh)}
          />
        </div>
      </>
    );
  }
}
