import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { FaUser } from "react-icons/fa";

import * as constants from "../constants";
import * as api from "../Services/apiClient";
import * as FiltroHelper from "../Utils/FiltroHelper";
import LocalStorage from "../Utils/LocalStorage";

import Sidebar from "../Components/Sidebar";
import Aviso from "../Components/Aviso";
import ModalErro from "../Components/ModalErro";
import ErrorBoundary from "../Components/ErrorBoundary";
import PrivateWrapper from "../Components/PrivateWrapper";
import PrivateRoute from "../Components/PrivateRoute";

import ModalConfiguracoes from "../Components/ModalConfiguracoes";

import Login from "../Pages/Login";
import Atividades from "../Pages/Atividades";
import Dashboard from "../Pages/Dashboard";
import Colaboradores from "../Pages/Colaboradores";

class AppRouter extends React.Component {
  INITIAL_STATE = {
    isLoading: false,
    filtro: FiltroHelper.FILTERS_DATE["hoje"],
    shouldShowSettings: false,
    error: null,
    departamentos: [],
    categorias: [],
    empresas: [],
    colaboradores: [],
    codigo_departamento_padrao: null,
    codigo_categoria_padrao: null,
    codigo_grupo_empresa_padrao: null,
    tipo_usuario: constants.TipoUsuario.Colaborador,
    tem_acesso_colaboradores: false,
    shouldResetUser: false,
    usuarioAtividade: null,
    usuario: null
  };
  state = this.INITIAL_STATE;

  componentDidMount() {
    this.getOptions();
  }

  getOptions = () => {
    if (!LocalStorage.isLogged()) return;
    this.setState({
      ...this.state,
      isLoading: true
    });
    api
      .getOpcoes()
      .then(response => {
        this.setState({
          isLoading: false,
          departamentos: response.departamentos,
          categorias: response.categorias.map(constants.CodigoDescricaoMapper),
          tipo_usuario: response.tipo_usuario,
          usuarioAtividade: LocalStorage.usuario(),
          empresas: response.empresas.map(constants.CodigoDescricaoMapper),
          codigo_departamento_padrao: response.codigo_departamento_padrao,
          codigo_categoria_padrao: response.codigo_categoria_padrao,
          codigo_grupo_empresa_padrao: response.codigo_grupo_empresa_padrao,
          tem_acesso_colaboradores: response.tem_acesso_colaboradores,
          colaboradores: (response.colaboradores || [])
            .sort((a, b) => (a.nome > b.nome ? 1 : -1))
            .map(constants.CodigoNomeMapper)
        });
      })
      .catch(error => {
        this.setState({
          ...this.state,
          isLoading: false
        });
        this.onError("Ocorreu um erro ao buscar as opções do usuário", error);
      });
  };

  onFilterChanged = filtro => {
    this.setState({
      ...this.state,
      filtro: filtro
    });
  };
  toggleSettings = () => {
    this.setState({
      ...this.state,
      shouldShowSettings: !this.state.shouldShowSettings
    });
  };

  onUsuarioAtividadeChanged = codigo => {
    this.setState({
      usuarioAtividade: JSON.parse(codigo)
    });
  };

  onError = (mensagem, error) => {
    if (error) console.error(error);
    if (error && error.status && error.status === 401) return; //não mostra message box se for unauthorized
    if (mensagem && mensagem !== "") this.setState({ error: mensagem });
  };

  dismissError = () => {
    this.setState({ error: null });
  };

  onOptionsChanged = (categoria, empresa) => {
    this.setState({
      codigo_categoria_padrao: categoria,
      codigo_grupo_empresa_padrao: empresa
    });
  };

  onAtividadeIniciada = () => {
    this.setState({
      usuarioAtividade: LocalStorage.usuario(),
      shouldResetUser: true
    });
  };

  onUserReset = () => {
    this.setState({
      shouldResetUser: false
    });
  };

  onLogout = () => {
    this.setState({
      ...this.INITIAL_STATE
    });
  };

  render() {
    const {
      filtro,
      error,
      shouldShowSettings,
      departamentos,
      categorias,
      codigo_departamento_padrao,
      codigo_categoria_padrao,
      codigo_grupo_empresa_padrao,
      tipo_usuario,
      usuarioAtividade,
      empresas,
      shouldResetUser,
      tem_acesso_colaboradores,
      colaboradores,
      isLoading
    } = this.state;
    const options = {
      departamentos: departamentos,
      categorias: categorias,
      empresas: empresas,
      codigo_departamento_padrao: codigo_departamento_padrao,
      codigo_categoria_padrao: codigo_categoria_padrao,
      codigo_grupo_empresa_padrao: codigo_grupo_empresa_padrao,
      tipo_usuario: tipo_usuario,
      tem_acesso_colaboradores: tem_acesso_colaboradores,
      colaboradores: colaboradores
    };

    let sidebar = (
      <Sidebar
        onFilterChanged={this.onFilterChanged}
        options={options}
        usuario={LocalStorage.usuario()}
        shouldResetUser={shouldResetUser}
        onUserReset={this.onUserReset}
        onUsuarioAtividadeChanged={this.onUsuarioAtividadeChanged}
        filtro={filtro}
      />
    );

    return (
      <Router>
        <>
          <div className="body-container h-100">
            <div className="row mr-0 ml-0 h-100">
              <ErrorBoundary>
                {isLoading && (
                  <div className="has-navbar-no-sidebar white-overlay">
                    <Aviso
                      mensagem={"Carregando seus dados..."}
                      icone={<FaUser />}
                    />
                  </div>
                )}
                <Route exact path="/">
                  <Redirect to="/atividades" />
                </Route>

                <PrivateRoute
                  exact
                  hasNavbar
                  path="/atividades"
                  sidebar={sidebar}
                  options={options}
                  filtro={filtro}
                  component={Atividades}
                  onAtividadeIniciada={this.onAtividadeIniciada}
                  usuarioAtividade={usuarioAtividade}
                  onError={this.onError}
                  isLoading={this.isLoading}
                  toggleSettings={this.toggleSettings}
                  onLogout={this.onLogout}
                />
                <PrivateRoute
                  exact
                  hasNavbar
                  path="/dashboard"
                  sidebar={sidebar}
                  options={options}
                  filtro={filtro}
                  component={Dashboard}
                  usuarioAtividade={usuarioAtividade}
                  onError={this.onError}
                  isLoading={this.isLoading}
                  toggleSettings={this.toggleSettings}
                  onLogout={this.onLogout}
                />
                <PrivateRoute
                  hasNavbar
                  exact
                  path="/colaboradores"
                  component={Colaboradores}
                  options={options}
                  onError={this.onError}
                  isLoading={this.isLoading}
                  toggleSettings={this.toggleSettings}
                  onLogout={this.onLogout}
                />

                <PrivateWrapper>
                  <ModalConfiguracoes
                    onOptionsChanged={this.onOptionsChanged}
                    isOpen={shouldShowSettings}
                    onCancel={this.toggleSettings}
                    options={options}
                  />
                </PrivateWrapper>
                <Route
                  path="/login"
                  render={props => (
                    <Login handleLoginSuccess={this.getOptions} {...props} />
                  )}
                />
              </ErrorBoundary>
            </div>
          </div>
          <ModalErro
            isOpen={error !== null}
            onCancel={this.dismissError}
            message={error}
          />
        </>
      </Router>
    );
  }
}

export default AppRouter;
