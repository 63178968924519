import React from "react";
import { FaChartLine } from "react-icons/fa";

import * as api from "../../Services/apiClient";

import ActOnFilterChange from "../../Components/ActOnFilterChange";
import Aviso from "../../Components/Aviso";
import DashboardPresenter from "../../Components/Dashboard";

export default class Dashboard extends ActOnFilterChange {
  state = {
    dados: null
  };

  doActOnFilterChange = (codigoUsuario, filtro) => {
    api
      .get(`/atividade/dashboard/${codigoUsuario}/${filtro}`)
      .then(response => {
        this.setState({
          isLoading: false,
          dados: response
        });
      })
      .catch(error => {
        this.props.onError(
          "Um erro ocorreu ao buscar os dados do dashboard",
          error
        );
        this.setState({
          isLoading: false
        });
      });
  };
  render() {
    const { isLoading, dados } = this.state;
    const { sidebar } = this.props;
    return (
      <>
        <div className="col-md-2 px-0">{sidebar}</div>
        <div className="col-md-10">
          <div className="has-navbar">
            {isLoading && (
              <Aviso
                mensagem={"Carregando seu dashboard..."}
                icone={<FaChartLine />}
              />
            )}
            {!isLoading && <DashboardPresenter dados={dados} />}
          </div>
        </div>
      </>
    );
  }
}
