import * as DateHelper from "./DateHelper"

export const getFiltro = (props) => {
  const sp = new URLSearchParams(props.location.search)
  return sp.get('filtro');
}

const getFilterSemana = () => {
  let inicio = DateHelper.getDate().isoWeekday(1).startOf('week')
  let fim = DateHelper.getDate().isoWeekday(1).endOf('week')

  return getFormattedFilter(inicio, fim)
}

const getFilterMes = () => {
  const dia = DateHelper.getDate().date();
  const mesesParaSubtrair = dia >= 25 ? 0 : 1
  const mesesParaAdicionar = dia >= 25 ? 1 : 0;
  let inicio = DateHelper.getDate().subtract(mesesParaSubtrair, 'months').date(25)
  let fim = DateHelper.getDate().add(mesesParaAdicionar, 'months').date(24)

  return getFormattedFilter(inicio, fim)
}

const getFilterMesRetroativo = () => {
  const dia = DateHelper.getDate().date();
  const mesesParaSubtrair = dia >= 25 ? 1 : 2
  let inicio = DateHelper.getDate().subtract(mesesParaSubtrair, 'months').date(25)
  let fim = DateHelper.getDate().subtract(mesesParaSubtrair - 1, 'months').date(24)

  return getFormattedFilter(inicio, fim)
}

export const getStartDate = (filter) => {
  var date = filter.split("/")[0];
  return parseFilterDate(date);
}

export const getEndDate = (filter) => {
  var date = filter.split("/")[1];
  return parseFilterDate(date);
}

const parseFilterDate = (date) => {
  return DateHelper.getDateFormatted(DateHelper.parseDate(date, DateHelper.API_DATE_FORMAT), DateHelper.BR_FORMAT);
}

export const getFormattedFilter = (inicio, fim) => {
  if (typeof inicio === 'string') {
    inicio = DateHelper.parseDate(inicio)
    fim = DateHelper.parseDate(fim)
  }
  if (!inicio.isValid() || !fim.isValid())
    throw new Error("Datas inválidas");
  return `${inicio.format(DateHelper.API_DATE_FORMAT)}/${fim.format(DateHelper.API_DATE_FORMAT)}`
}

export const FILTERS_DATE = {
  hoje: getFormattedFilter(DateHelper.getDate(), DateHelper.getDate()),
  semana: getFilterSemana(),
  mes: getFilterMes(),
  mesRetroativo: getFilterMesRetroativo(),
  seteDias: getFormattedFilter(DateHelper.getDate().add(-7, 'days'), DateHelper.getDate()),
  trintaDias: getFormattedFilter(DateHelper.getDate().add(-30, 'days'), DateHelper.getDate())
}