import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "../../Modal";

class ModalAvisoRelatorio extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func
  };

  render() {
    const { isOpen, onCancel } = this.props;

    return (
      <Modal title="RELATÓRIO EXPORTADO" isOpen={isOpen} onClose={onCancel}>
        <p className="pt-3">Seu relatório foi exportado com sucesso.</p>
        <h4 className="text-danger pb-3">
          CONFIRA SUAS ATIVIDADES ANTES DE GERAR SUA NOTA FISCAL.
        </h4>
        <button className="btn btn-primary float-right" onClick={onCancel}>
          Ok
        </button>
      </Modal>
    );
  }
}

export default ModalAvisoRelatorio;
