import React from "react";
import * as DateHelper from "../../Utils/DateHelper";

export default class TickContainer extends React.Component {
  state = {
    now: DateHelper.getDate()
  };
  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  tick() {
    this.setState({
      now: DateHelper.getDate()
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return (
      <>
        {React.Children.map(this.props.children, child =>
          React.cloneElement(child, { now: this.state.now })
        )}
      </>
    );
  }
}
