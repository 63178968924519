import React, { Component } from "react";
import { IoIosWarning } from "react-icons/io";
import * as DateHelper from "../../../Utils/DateHelper";

import AcoesAtividade from "../AcoesAtividade";
import DropDownAtividade from "../DropDownAtividade";
import NewTaskNotification from "../NewTaskNotification";

import "./Task.css";

export default class CardAtividade extends Component {
  render() {
    const {
      atividade,
      now,
      onEdit,
      onDelete,
      onFinish,
      canShowOptions,
      ...rest
    } = this.props;
    return (
      <div className="row" key={atividade.codigo} {...rest}>
        <div className="col-12 mb-3">
          <div
            className={`card task ${atividade.conclusao === null &&
              " is-running"} ${atividade.isNew && " is-success"}`}
            id={atividade.codigo}
          >
            <div className="card-body">
              <h5 className="card-title">
                <div className="d-block">
                  <span className="day-of-week ">
                    {DateHelper.getDateFormatted(
                      atividade.inicio,
                      "ddd, DD MMM, YYYY"
                    )}
                  </span>
                  {!atividade.valida && (
                    <span className="text-warning float-right">
                      <IoIosWarning />
                    </span>
                  )}
                </div>

                <span className="text-muted">
                  {DateHelper.getDateFormatted(atividade.inicio, "HH:mm")} -{" "}
                  {atividade.conclusao ? (
                    DateHelper.getDateFormatted(atividade.conclusao, "HH:mm")
                  ) : (
                    <i>agora</i>
                  )}
                </span>
                <strong className="float-right task__current-time">
                  <span>
                    {DateHelper.getDurationFormatted(
                      DateHelper.dateDiff(
                        atividade.inicio,
                        atividade.conclusao || now
                      )
                    )}
                  </span>
                </strong>
              </h5>
              <div className="card-text">
                <p>
                  <strong className="task__description">
                    {atividade.descricao || (
                      <em className="text-muted">(sem título)</em>
                    )}
                  </strong>
                </p>

                <div className="d-block">
                  {atividade.grupo_empresa && (
                    <small title="Empresa" className="text-muted tag mr-1 mb-1">
                      {atividade.grupo_empresa.nome}
                    </small>
                  )}
                  <small title="Departamento" className="tag mr-1 mb-1">
                    {atividade.departamento
                      ? atividade.departamento.descricao
                      : "auto"}
                  </small>
                  {atividade.categoria && (
                    <small title="Empresa" className="tag mb-1">
                      {atividade.categoria.descricao}
                    </small>
                  )}
                </div>
              </div>
              <div className="text-right clearfix">
                {canShowOptions && (
                  <>
                    <DropDownAtividade
                      onEdit={() => onEdit(atividade)}
                      onDelete={() => onDelete(atividade)}
                      onFinish={() => onFinish(atividade)}
                      showFinish={atividade.conclusao == null}
                      togglerContent={"Ações"}
                      className="d-block d-sm-none"
                    />
                    <AcoesAtividade
                      onEdit={() => onEdit(atividade)}
                      onDelete={() => onDelete(atividade)}
                      onFinish={() => onFinish(atividade)}
                      showFinish={atividade.conclusao == null}
                      inline={true}
                      className="d-none d-sm-inline"
                    />
                  </>
                )}
              </div>
            </div>
            <NewTaskNotification />
          </div>
        </div>
      </div>
    );
  }
}
