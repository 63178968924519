import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import * as DateHelper from "../../../Utils/DateHelper";

class PainelBuscar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inicio: DateHelper.getDate(),
      fim: DateHelper.getDate(),
      errors: null
    };
  }

  handleDateChange = (name, date) => {
    this.setState({
      ...this.state,
      [name]: date
    });
  };

  enviarBusca = event => {
    event.preventDefault(); //evita enviar o submit padrão da pagina

    this.setState({
      errors: null
    });

    if (!this.state.fim) {
      this.setState({
        errors: true
      });
      return;
    }

    this.props.onFilterChanged(this.state.inicio, this.state.fim);
    this.props.postSubmit();
  };

  render() {
    const { inicio, fim } = this.state;
    const { onFilterChanged, postSubmit, ...rest } = this.props;

    return (
      <div className="card mt-2 pt-4 pb-4 pl-2 pr-2" {...rest}>
        <form noValidate onSubmit={this.enviarBusca}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              clearable
              className="mb-4 w-100"
              clearLabel="limpar"
              showTodayButton={true}
              todayLabel="hoje"
              cancelLabel="cancelar"
              invalidDateMessage="Data inválida"
              ampm={false}
              label="Início"
              inputVariant="outlined"
              name="inicio"
              value={inicio}
              onChange={date => this.handleDateChange("inicio", date)}
              format={DateHelper.DEFAULT_FORMAT}
            />
            <br />
            <KeyboardDatePicker
              className="mb-4 w-100 float-right"
              clearable
              clearLabel="limpar"
              showTodayButton={true}
              todayLabel="hoje"
              cancelLabel="cancelar"
              invalidDateMessage="Data inválida"
              ampm={false}
              label="Fim"
              inputVariant="outlined"
              name="fim"
              value={fim}
              onChange={date => this.handleDateChange("fim", date)}
              format={DateHelper.DEFAULT_FORMAT}
            />
          </MuiPickersUtilsProvider>
          <button type="submit" className="btn btn-primary w-100">
            <FaSearch />
            &nbsp;Buscar
          </button>
        </form>
      </div>
    );
  }
}

export default PainelBuscar;
