export const AUTH_KEY = "AUTH_KEY";
export const NOME_USUARIO = "NOME_USUARIO";
export const CODIGO_USUARIO_ATIVIDADES = "CODIGO_USUARIO_ATIVIDADES";
export const CODIGO_USUARIO = "CODIGO_USUARIO";
export const ENV_VARS = { env: "prod" };
export const APIBASE = "https://api.setcorp.com.br/";
//export const APIBASE = "http://localhost:54608/";
export const ViewsAtividades = { card: "card", list: "list" };
export const TipoUsuario = { Colaborador: 0, Gerente: 1, Presidente: 2 };
export const TiposCalculoHora = [
  { value: 0, label: "Com Retenção" },
  { value: 1, label: "Sem Retenção" }
];
export const CodigoDescricaoMapper = item => {
  return { value: item.codigo, label: item.descricao };
};
export const CodigoNomeMapper = item => {
  return { value: item.codigo, label: item.nome };
};

export const OrderByNome = (a, b) => (a.nome > b.nome ? 1 : -1);
export const OrderByUsuarioNome = (a, b) =>
  a.usuario.nome > b.usuario.nome ? 1 : -1;
