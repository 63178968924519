import React from "react";

class Collapsable extends React.Component {
  state = {
    isOpen: false
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  render() {
    const { icon } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <button onClick={() => this.toggle()} className="navbar-toggler">
          <span>{icon}</span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen && "show"}`}>
          {this.props.children}
        </div>
      </>
    );
  }
}

export default Collapsable;
