import React from "react";
import PropTypes from "prop-types";

export const Button = ({
  className,
  isLoading,
  styleButton,
  label,
  textUpperCase = false,
  ...rest
}) => (
  <button
    disabled={isLoading}
    className={`btn ${styleButton && "btn-" + styleButton} ${textUpperCase &&
      " text-uppercase"} ${className}`}
    {...rest}
  >
    {isLoading ? isLoading : label}
  </button>
);

Button.propTypes = {
  styleButton: PropTypes.oneOf([
    "primary",
    "success",
    "danger",
    "warning",
    "info"
  ])
};
