import React, { Component } from "react";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import { FaClock } from "react-icons/fa";
import "./ModalAtividades.css";

import * as api from "../../../Services/apiClient";
import * as DateHelper from "../../../Utils/DateHelper";
import * as constants from "../../../constants";

import Modal from "../../Modal";
import { Select, TextAreaInput } from "../../Form";
import { Button } from "../../Button";

class ModalCriarAtividade extends Component {
  state = {
    departamentos: [],
    categorias: [],
    empresas: [],
    form: {
      departamento: "",
      categoria: "",
      empresa: "",
      inicio: DateHelper.getDate(),
      conclusao: null,
      descricao: ""
    },
    initialForm: {
      departamento: "",
      categoria: "",
      empresa: "",
      inicio: null,
      conclusao: null,
      descricao: ""
    },
    isSaving: false,
    error: null,
    selectedTab: 1
  };
  static propTypes = {
    isOpen: PropTypes.bool,
    onCreate: PropTypes.func,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    atividade: PropTypes.object
  };

  handleChange = event => {
    this.setState({
      error: null
    });

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [event.target.name]: event.target.value
      }
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: date
      }
    });
  };

  handleSelectorChange = (name, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  criarOuEditarAtividade = e => {
    e.preventDefault(); //evita enviar o submit padrão da pagina

    this.setState({
      error: null
    });

    const validacao = DateHelper.validarDatasAtividade(
      this.state.form.inicio,
      this.state.form.conclusao
    );
    if (!validacao.isValid) {
      this.setState({
        error: validacao.message
      });
      return;
    }

    const { form } = this.state;
    const inicio = form.inicio || DateHelper.getDate();

    let request = {
      descricao: form.descricao,
      codigo_departamento: parseInt(form.departamento),
      codigo_categoria: parseInt(form.categoria),
      codigo_grupo_empresa: parseInt(form.empresa),
      inicio: DateHelper.getDateFormatted(
        inicio,
        DateHelper.API_DATE_TIME_FORMAT
      ),
      conclusao: form.conclusao
        ? DateHelper.getDateFormatted(
            form.conclusao,
            DateHelper.API_DATE_TIME_FORMAT
          )
        : null
    };

    this.setState({
      isLoading: true
    });

    let promise = undefined;
    if (this.isEditing)
      promise = api.editarAtividade(this.state.form.codigo, request);
    else promise = api.criarAtividade(request);

    promise
      .then(response => {
        this.setState({
          isLoading: false,
          form: this.state.initialForm
        });
        if (this.isEditing) this.props.onEdit(response);
        else {
          response = {
            ...response,
            isNew: true
          };
          this.props.onCreate(response);
        }

        this.onCancel();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error:
            error.message ||
            `Erro ao ${this.isEditing ? "editar" : "criar"} a atividade`,
          isLoading: false
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen) {
      console.log(this.props);
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          departamento: this.props.codigo_departamento_padrao,
          categoria: this.props.codigo_categoria_padrao,
          empresa: this.props.codigo_grupo_empresa_padrao
        },
        selectedTab: 1
      });

      const atividade = this.props.atividade;
      this.isEditing = atividade !== undefined;
      if (this.isEditing) {
        this.setState({
          form: {
            codigo: atividade.codigo,
            departamento: atividade.codigo_departamento,
            categoria: atividade.codigo_categoria,
            inicio: atividade.inicio,
            conclusao: atividade.conclusao,
            descricao: atividade.descricao,
            empresa: atividade.codigo_grupo_empresa
          }
        });
      }
    }
  }

  setDataConclusao = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        conclusao: DateHelper.getDate()
      }
    });
  };

  onCancel = () => {
    this.props.onCancel();
    this.setState({
      form: this.state.initialForm,
      error: null
    });
    this.isEditing = false;
  };
  selectTab = tabNumber => {
    this.setState({ selectedTab: tabNumber });
  };

  render() {
    const { categorias, empresas, isOpen } = this.props;
    const departamentos = this.props.departamentos.map(
      constants.CodigoDescricaoMapper
    );
    const { form, isSaving, isLoading, error, selectedTab } = this.state;
    const {
      empresa,
      departamento,
      categoria,
      inicio,
      conclusao,
      descricao
    } = form;

    const departamentoValue = departamentos.filter(
      d => d.value === departamento
    )[0];
    const categoriaValue = categorias.filter(c => c.value === categoria)[0];
    const empresaValue = empresas.filter(e => e.value === empresa)[0];

    return (
      <Modal
        title={this.isEditing ? "EDITAR ATIVIDADE" : "NOVA ATIVIDADE"}
        isOpen={isOpen}
        onClose={this.onCancel}
      >
        <form noValidate onSubmit={this.criarOuEditarAtividade}>
          <ul className="nav nav-tabs mb-4">
            <li
              className={`nav-link ${selectedTab === 1 ? "active" : ""}
              `}
            >
              <button
                className="btn btn-link border-0 nav-link p-0"
                onClick={e => {
                  e.preventDefault();
                  this.selectTab(1);
                }}
              >
                Básico
              </button>
            </li>
            <li
              className={`nav-link ${selectedTab === 2 ? "active" : ""}
              `}
            >
              <button
                className="btn btn-link border-0 nav-link p-0"
                onClick={e => {
                  e.preventDefault();
                  this.selectTab(2);
                }}
              >
                Avançado
              </button>
            </li>
          </ul>

          {selectedTab === 1 && (
            <>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  clearable
                  clearLabel="limpar"
                  className="pb-2"
                  showTodayButton={true}
                  todayLabel="agora"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data inválida"
                  ampm={false}
                  label="Início"
                  inputVariant="outlined"
                  name="inicio"
                  value={inicio}
                  onChange={date => this.handleDateChange("inicio", date)}
                  format={DateHelper.PICKER_FORMAT_WITH_TIME}
                />
                <div className="pb-2">
                  <KeyboardDateTimePicker
                    clearable
                    clearLabel="limpar"
                    showTodayButton={true}
                    todayLabel="agora"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data inválida"
                    ampm={false}
                    label="Conclusão"
                    inputVariant="outlined"
                    name="conclusao"
                    value={conclusao}
                    onChange={date => this.handleDateChange("conclusao", date)}
                    format={DateHelper.PICKER_FORMAT_WITH_TIME}
                  />
                  <button
                    className="btn btn-secondary btn-picker ml-2"
                    onClick={this.setDataConclusao}
                    title="Clique aqui para inserir a data e hora atual na conclusão da atividade"
                  >
                    <FaClock />
                  </button>
                </div>
              </MuiPickersUtilsProvider>

              <TextAreaInput
                value={descricao || ""}
                onChange={this.handleChange}
                disabled={isLoading}
                label="Descrição"
                name="descricao"
                maxLength={500}
              />
            </>
          )}
          {selectedTab === 2 && (
            <>
              <Select
                label="Empresa"
                name="empresa"
                isClearable
                options={empresas}
                value={empresaValue}
                onChange={e =>
                  this.handleSelectorChange("empresa", e ? e.value : null)
                }
                disabled={isLoading}
              />

              <Select
                label="Departamento"
                name="departamento"
                isClearable
                options={departamentos}
                value={departamentoValue}
                onChange={e =>
                  this.handleSelectorChange("departamento", e ? e.value : null)
                }
                disabled={isLoading}
              />

              <Select
                label="Categoria"
                name="categoria"
                isClearable
                options={categorias}
                value={categoriaValue}
                onChange={e =>
                  this.handleSelectorChange("categoria", e ? e.value : null)
                }
                disabled={isLoading}
              />
            </>
          )}
          {error && <div className="alert alert-danger">{error}</div>}
          <Button
            label={(this.isEditing ? "Editar" : "Criar") + " Atividade"}
            styleButton="primary"
            className="float-right"
            isLoading={isSaving && "Criando..."}
            disabled={isLoading}
          />
        </form>
      </Modal>
    );
  }
}

export default ModalCriarAtividade;
