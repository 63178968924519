import moment from "moment";
require("moment/locale/pt-br");
moment.locale("pt-br");

export const DEFAULT_FORMAT = "DD-MM-YYYY";
export const BR_FORMAT = "DD/MM/YYYY";
export const BR_FORMAT_WITH_TIME = "DD/MM/YYYY HH:mm:ss";
export const DEFAULT_FORMAT_WITH_TIME = "DD-MM-YYYYTHH:mm:ss";
export const PICKER_FORMAT_WITH_TIME = "DD-MM-YYYY HH:mm:ss";
export const DEFAULT_TIME = "HH:mm:ss";
export const API_DATE_FORMAT = "YYYY-MM-DD";
export const API_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const getDate = (date = null) => {
  return date ? moment(date) : moment();
};

export const getDateFormatted = (date = null, format = DEFAULT_FORMAT) => {
  return getDate(date).format(format);
};

export const formatDateTimeBR = (date = null) =>
  getDateFormatted(date, BR_FORMAT_WITH_TIME);

export const formatDateBR = (date = null) => getDateFormatted(date, BR_FORMAT);

export const parseDate = (
  date,
  format = DEFAULT_FORMAT,
  returnIfNull = false
) => {
  if (returnIfNull && (date === null || date === undefined)) return undefined;
  return moment(date, format);
};

export const dateDiff = (first, second) => {
  const inicio = moment(first);
  const fim = moment(second || moment());
  return fim.diff(inicio);
};

export const getDurationFormatted = date => {
  return formatDuration(getDuration(date));
};

export const formatTime = time => {
  const duration = asDuration(time);
  return formatDuration(getDuration(duration));
};

export const validarDatasAtividade = (inicio, fim) => {
  const valid = {
    isValid: true,
    message: null
  };

  inicio = ensureDateAsMoment(inicio);
  fim = ensureDateAsMoment(fim);

  if (!inicio && !fim) return valid;

  if (fim && !inicio)
    return {
      isValid: false,
      message: "Especifique uma data inicial."
    };

  if (inicio && !fim && !isSameDay(inicio, getDate()))
    return {
      isValid: false,
      message:
        "Não é possível criar atividade em andamento numa data retroativa."
    };

  if (fim && fim < inicio)
    return {
      isValid: false,
      message: "Data inicial não pode ser maior do que data final."
    };

  if (inicio && fim) {
    if (!isSameDay(inicio, fim)) {
      return {
        isValid: false,
        message: "As datas de início e fim devem ser no mesmo dia."
      };
    }
  }

  return valid;
};

export const validarDatasValorHora = (inicio, fim, criandoRetroativo) => {
  const valid = {
    isValid: true,
    message: null
  };

  inicio = ensureDateAsMoment(inicio);
  fim = ensureDateAsMoment(fim);

  if (!criandoRetroativo && !inicio && !fim) return valid;
  if (criandoRetroativo && !inicio)
    return {
      isValid: false,
      message: "Especifique uma data inicial para valor/hora retroativo."
    };

  if (inicio >= getDate().startOf("day"))
    return {
      isValid: false,
      message: "Início da vigência não pode na data atual ou futura."
    };
  if (fim && !inicio)
    return {
      isValid: false,
      message: "Especifique uma data inicial."
    };

  if (fim && fim < inicio)
    return {
      isValid: false,
      message: "Data inicial não pode ser maior do que data final."
    };

  return valid;
};

export const isSameDay = (date, other) => {
  return (
    date.date() === other.date() &&
    date.month() === other.month() &&
    date.year() === other.year()
  );
};

const ensureDateAsMoment = date => {
  if (date === null) return null;
  let ini = getDate(date);
  if (!ini.isValid) ini = parseDate(date, API_DATE_TIME_FORMAT);
  return ini;
};

export const formatTimeSpan = ts => {
  if (!ts) return "00:00:00";
  return [
    formatWithTrailingZero(ts.days * 24 + ts.hours),
    formatWithTrailingZero(ts.minutes),
    formatWithTrailingZero(ts.seconds)
  ].join(":");
};

const formatWithTrailingZero = number => {
  return number >= 10 ? number : "0" + number;
};

const getDuration = date => {
  return moment.duration(date);
};

const formatDuration = duration => {
  var hours = Math.floor(duration.asHours());
  var mins = Math.floor(duration.minutes());
  var sec = Math.floor(duration.seconds());
  return (
    (hours > 9 ? hours : "0" + hours) +
    ":" +
    (mins > 9 ? mins : "0" + mins) +
    ":" +
    (sec > 9 ? sec : "0" + sec)
  );
};

const asDuration = time => {
  let total = time.split(":");
  return {
    hours: total[0],
    minutes: total[1],
    seconds: total[2]
  };
};
